.menu {

	white-space: nowrap;

	&__list {

		display: flex;
		justify-content: center;

		@media #{$mdWidth} {

			display: block;

		}

	}

	&__item {

		margin-right: 48px;

		@media #{$mdWidth} {

			margin: 36px 0;

		}

	}

	&__link {
		font-size: 15px;
		font-weight: 500;
		display: block;
        transform: var(--transitionDefault);
        color: #000;

        &.is-current{
			color: var(--primary);
			pointer-events: none;
		}

		&.router-link-exact-active,
		&.router-link-active {
			color: var(--primary);
			pointer-events: none;
		}

		@media #{$desktop} {
			&:hover {
				color: color(var(--primary) a(50%));
			}
		}

		@media #{$mdWidth} {
			font-size: 20px;
			text-align: center;
		}

	}

	&__link:hover{
		color: var(--primary);
	}

}