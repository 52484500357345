.billboard {

	.center {

		width: 1380px;
		padding: 0 20px;

	}

	&__box {

		background-color: #c4c4c4;
		border-radius: 10px;
		position: relative;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		height: 623px;

		@media #{$mdWidth} {

			height: 400px;

		}

		@media #{$smWidth} {

			height: 240px;

		}

		&::after {

			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			content: '';
			opacity: .8;
			background-color: var(--bg);

		}

	}

	&__content {

		position: relative;
		z-index: 1;
		width: 1080px;
		max-width: 100%;
		padding: 40px;

	}

	&__title {

        font-weight: 300;
        
        .h1-main {
            text-transform: uppercase;
            font-size: 80px;

            @media #{$mdWidth} {

                font-size: 50px;
    
            }

            @media #{$smWidth} {

                font-size: 20px;
    
            }
        }

    }

    &__button {
        margin-top: 100px;
        display: inline-block;

        @media #{$mdWidth} {

            margin-top: 40px;
            font-size: 14px;

        }

        @media #{$smWidth} {

            margin-top: 15px;
            font-size: 12px;

        }
    }
    
    &__subtitle {
        display: inline-block;
        margin-top: 60px;

        @media #{$mdWidth} {

            margin-top: 30px;

        }

        @media #{$smWidth} {

            margin-top: 10px;
            font-size: 20px;
            line-height: 24px;

        }
    }

	&__img {

		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;

	}

}