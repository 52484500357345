/*! nouislider - 14.6.2 - 9/16/2020 */
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
 .noUi-target,
 .noUi-target * {
   -webkit-touch-callout: none;
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
   touch-action: none;
   user-select: none;
 }
 .noUi-target {
   position: relative;
 }
 .noUi-base,
 .noUi-connects {
   width: 100%;
   height: 100%;
   position: relative;
   z-index: 1;
 }
 /* Wrapper for all connect elements.
  */
 .noUi-connects {
   overflow: hidden;
   z-index: 0;
 }
 .noUi-connect,
 .noUi-origin {
   will-change: transform;
   position: absolute;
   z-index: 1;
   top: 0;
   right: 0;
   transform-origin: 0 0;
   transform-style: flat;
 }
 .noUi-connect {
   height: 100%;
   width: 100%;
   background-color: var(--bg);
 }
 .noUi-origin {
   height: 10%;
   width: 10%;
 }
 /* Give origins 0 height/width so they don't interfere with clicking the
  * connect elements.
  */
 .noUi-horizontal .noUi-origin {
   height: 0;
 }
 .noUi-touch-area {
   height: 100%;
   width: 100%;
 }
 .noUi-state-tap .noUi-connect,
 .noUi-state-tap .noUi-origin {
   transition: transform 0.3s;
 }
 .noUi-horizontal {
   height: 2px;
 }
 .noUi-handle {
   position: absolute;
   right: -12px;
   top: -11px;
   width: 24px;
   height: 24px;
   cursor: pointer;
   border-radius: 50%;
   background-color: var(--yellow);
 }