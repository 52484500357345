.artwork-list {
  margin: 52px 0;

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -18px;

    @media #{$smWidth} {
      display: block;
      margin: 0;
    }
  }

  &__item {
    background-color: #fff;
    box-shadow: 0px 4px 34px rgba(31, 31, 31, 0.13);
    border-radius: 12px;
    width: 336px;
    margin: 18px;
    padding: 36px;

    @media #{$smWidth} {
      max-width: 100%;
      padding: 24px 20px;
      margin: 18px auto;
    }

    @media #{$xsWidth} {
      padding: 20px 16px 24px;
    }
  }

  &__img {
    position: relative;
    margin: 0 auto 31px;
    display: block;
    color: #c4c4c4;
    width: 264px;
    height: 170px;
    background-color: #c4c4c4;

    img{
      object-fit: cover;
    }

    @media #{$smWidth} {
      margin-bottom: 36px;
    }

    &::after {
      position: absolute;
      top: -24px;
      left: -24px;
      right: -24px;
      bottom: -24px;
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg width='312' height='218' viewBox='0 0 312 218' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M277 24H24v170h265V24h-12zm0 12H36v146h241V36z' fill='%23fff'/%3E%3C/g%3E%3Cpath fill='%230F0F0F' d='M24 24h4v170h-4zM285 24h4v170h-4z'/%3E%3Cpath fill='%230F0F0F' d='M26 24h263v4H26zM26 190h263v4H26z'/%3E%3Cdefs%3E%3Cfilter id='filter0_d' x='18' y='18' width='285' height='190' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/%3E%3CfeOffset dx='4' dy='4'/%3E%3CfeGaussianBlur stdDeviation='5'/%3E%3CfeColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'/%3E%3CfeBlend in2='BackgroundImageFix' result='effect1_dropShadow'/%3E%3CfeBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");
    }
  }

  &__badge {
    position: absolute;
    width: 60px;
    height: 80px;
    top: -18px;
    left: -18px;
    z-index: 2;
    background-position: bottom;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='40' height='50' viewBox='0 0 40 50' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h40v50L20 38 0 50V0z' fill='%23EB5757'/%3E%3C/svg%3E");

    &-inner {
      width: 60px;
      height: 60px;
      display: block;
      border-radius: 50%;
      border: 6px solid #fff;
      line-height: 48px;
      text-align: center;
      font-size: 19px;
      color: #1d1d1d;
      background-color: var(--yellow);
    }

    &-inner2 {
      background-color: #d1d5dc
    }

    &-inner3 {
      background-color: #f8bd76
    }
  }

  &__title {
    margin: 7px 0;
    color: #0f0f0f;
    text-align: center;
  }

  &__author {
    text-align: center;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
  }

  &__hashtag {
    display: flex;
    justify-content: center;
    margin: 7px 0;

    p {
      text-decoration: underline;
      font-size: 16px;
      line-height: 21px;
      color: #0f0f0f;

      @media #{$desktop} {
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &__btn {
    width: 100%;
    display: block;
    margin: 24px 0;
  }

  &__desc {
    font-size: 14px;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    margin: 24px 0;
  }

  hr {
    background-color: #c4c4c4;
    opacity: 0.2;
    margin: 24px 0;
  }

  &__votes {
    font-size: 20px;
    line-height: 21px;
    color: #0f0f0f;
    text-align: center;
  }

  &__token {
    &-id {
      font-weight: 600;
      text-align: center;
      color: #0f0f0f;
      margin: 12px 0;
    }

    &-address {
      color: rgba(15, 15, 15, 0.5);
      font-size: 14px;
      text-align: center;
      margin: 15px 0 5px;
    }

    &-hash {
      font-size: 14px;
      text-align: center;
      margin: 5px 0 10px;

      a {
        text-decoration: underline;
        color: rgba(15, 15, 15, 0.5);
        word-break: break-all;

        @media #{$desktop} {
          &:hover {
            color: rgba(15, 15, 15, 1);
          }
        }
      }
    }
  }
}
