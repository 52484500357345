.transction-submitted {

	text-align: center;

	&__logo {

		width: 120px;
		height: 120px;
		border-radius: 50%;
		margin: 24px auto;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: rgba(250,208,106,.5);

		@media #{$smWidth} {

			width: 80px;
			height: 80px;

		}

		svg {

			fill: #000;

		}

	}

	.modal__title {
		color: #3F93F7;
		margin: 0;
	}

	&__link {

		text-align: center;
		margin: 12px 0;

	}

	&__loading {

		display: flex;
		justify-content: center;
		margin: 36px 0;

		svg {

			fill: var(--yellow);
			animation: loading 1s linear infinite;

		}

	}

	&__text {

		font-size: 14px;
		color: #808080;
		margin: 12px 0 60px;
		text-align: center;

	}

	&__btn {
		border: 1px var(--primary) solid;
		width: 100%;
		margin-top: 60px;

		@media #{$smWidth} {

			margin-top: 24px;

		}

	}

}


@keyframes loading {
	to {
		transform: rotate(360deg);
	}
}