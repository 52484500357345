.publish {
    width: 100%;
    height: 386px;
    background: linear-gradient(180deg, #56A3F8 0%, #ECF4FE 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 24px;
}

.publish-artwork {
    color: #9D9D9D;;
    max-width: 708px;
    padding: 0 64px;
    border-radius: 20px;
    padding-bottom: 84px;


    &__title {
        font-weight: 400;
        text-align: center;
        font-size: 42px;
        line-height: 24px;
        justify-content: center;
        display: flex;
        align-items: center;
        margin-bottom: 75px;
        color: #FFFFFF;
    }

    &__buy {
        &-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media #{$smWidth} {
                display: block;
            }

            &>div {
                display: flex;
                align-items: center;

                &:first-child {
                    @media #{$smWidth} {
                        margin-bottom: 16px;
                    }
                }
            }

            svg {
                margin-right: 16px;
            }
        }

        &-caption {
            margin-left: 24px;
            color: rgba(#fff, 0.8);
        }
    }

    &__inputbox {
        position: relative;
        margin: 36px 0;
        background-color: #ffffff;

        @media #{$smWidth} {
            margin: 24px 0;
        }
    }

    &__input {
        font-weight: 400;
        border: 0.3px solid #9D9D9D;
        box-sizing: border-box;
        border-radius: 8px;

        &:hover {
            border-color: var(--primary);
        }

        @media ($desktop) {
            &:hover {
                border-color: var(--primary);
            }
        }

        &:focus {
            border-color: var(--primary);
        }
    }

    &__label {
        display: block;
        font-weight: 500;
        font-size: 16px;
        line-height: 27px;
        margin: 12px 0;
        color: var(--primary);
    }

    &__drop {
        position: relative;
        min-height: 153px;
        border: 1px dashed #000;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        overflow: hidden;
        width: 380px;
        height: 380px;
        margin: auto;

        &:hover {
            opacity: 0.8;
        }

        @media ($desktop) {
            &:hover {
                background-color: rgba(255, 255, 255, 0.1);
            }
        }

        &-input {
            position: absolute;
            top: -10px;
            left: -10px;
            cursor: pointer;
            opacity: 0;
            font-size: 999px;
        }

        &-content {
            pointer-events: none;

            .cover {
                max-height: 500px;
                width: 100%;
                object-fit: contain !important;
            }
        }

        &-logo {
            margin: 12px auto;
            fill: var(--yellow);
            stroke: #000;
        }

        &-text {
            font-weight: bold;
            font-size: 14px;
            line-height: 21px;

            b {
                color: var(--yellow);
            }
        }

        &-format {
            font-size: 12px;
            line-height: 18px;
            opacity: 0.5;
            margin: 6px 0 12px;
            display: block;
        }
    }

    &__row {
        display: flex;
        margin: 36px 0;
        justify-content: space-between;

        @media #{$smWidth} {
            display: block;
            margin: 0;
        }

        &-item {
            width: calc(50% - 18px);

            @media #{$smWidth} {
                width: 100%;
                margin: 24px 0;
            }
        }
    }

    &__row &__inputbox {
        margin: 0;

        @media #{$smWidth} {
            margin: 24px 0;
        }
    }

    &__text-error {
        font-size: 14px;
        color: var(--error);
        position: relative;
        top: 12px;

        @media #{$smWidth} {
            margin: 12px 0 24px;
            top: 0;
        }
    }

    &__note {
        font-size: 16px;
        line-height: 27px;
        padding-left: 12px;
        margin: 36px 0;
        border-left: 4px solid var(--yellow);

        @media #{$smWidth} {
            margin: 24px 0;
        }
    }

    &__btn {
        width: 192px;
        margin: 36px auto;
        border: 1px solid #E5E5E5;
        box-sizing: border-box;
        box-shadow: 0px 5px 5px #E5E5E5;
        height: 50px;

        @media #{$smWidth} {
            margin: 24px auto 48px;
        }
    }
}
