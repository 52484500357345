.header {

	position: relative;
	border-bottom: 1px solid rgba(255, 255, 255, .1);

	.center {

		width: 1380px;
		padding: 0 20px;

	}

	&--bb0 {

		border-bottom-color: transparent;

	}

	&--app .center {

		width: 1440px;
		padding: 0 30px;

    }
    
    &.menu-show {
        border-bottom: transparent;
        
        .header__menu {
            display: block;
        }
    }

    &-account {
        display: flex;
        justify-content: inherit;
        align-items: center;
        border-radius: 100px;
        position: relative;
        transition: 1s;
        width: 100%;
        height: 44px;
		background: #FFFFFF;
		border: 2px solid #3F93F7;
		box-sizing: border-box;
		color: #3F93F7;

        @media #{$mdWidth} {

            width: 290px;
            position: absolute;
            height: 44px;
            top: 10px;
            right: 65px;

        }

        @media (max-width: 470px) {

            right: unset;
            left: 20px; 

        }

        @media (max-width: 370px) {

            width: 240px;

        }

        .balance {
            width: 160px;
            text-align: right;

            p {
				color: #3F93F7;
                margin-right: 16px;

                @media (max-width: 370px) {

                    margin-right: 8px;
        
                }
            }
        } 

        .address {
			padding: 0 8px;
            max-width: 153px;
            width: 100%;
            height: 100%;
            border-radius: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
			background: #3F93F7;
			color: #FFFFFF;

            @media (max-width: 370px) {

                max-width: 120px;
    
            }
        }

        .point {
            width: 10px;
            height: 10px;
            background: white;
            border-radius: 50%;
            margin-left: 16px;

            @media (max-width: 370px) {

                display: none;
    
            }
        }
    }

	&__box {

		display: flex;
		align-items: center;
		justify-content: space-between;
        height: 100px;
        min-height: 64px;

		@media #{$mdWidth} {

			display: block;
			height: auto;
			padding: 20px 0;

		}

	}

	&__logo {

        position: relative;

		svg {

            stroke: var(--color);
            
            @media #{$mdWidth} {

                width: 168px;
                height: 24px;
    
            }

        }

        @media (max-width: 580px) {

            display: none; 
    
        }
        
        &--small {
            display: none; 

            svg {

                height: 24px;
    
            }

            &.active {
                @media (max-width: 470px) {

                    display: none; 
        
                }
            }

            @media (max-width: 580px) {

                display: block; 
    
            }
        }

	}

	&__menu {

		display: flex;
		align-items: center;

		@media #{$mdWidth} {

			display: none;
			margin: 20px -20px 0;
			padding-top: 72px;
			border-top: 1px solid rgba(255, 255, 255, .1);

        }
        
        &-wrapper {
            display: flex;
            align-items: center;

            @media #{$mdWidth} {

                display: block;
    
            }
        }

	}

	&__btn {

		margin-left: 24px;

		@media #{$mdWidth} {

            display: block;
            position: absolute;
            top: 9px;
            right: 100px;

        }

        @media (max-width: 370px) {

            right: 75px;

        }
        
        .buttonContainer {
            width: 260px;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            @media #{$mdWidth} {

                width: auto;
    
            }
        }

	}

	&__lk {

		line-height: 38px;
		white-space: nowrap;
		background-color: rgba(255, 255, 255, .2);
		border-radius: 20px;
		display: flex;

		&-balance {

			padding: 0 12px;
			color: var(--color);

		}

		&-number {

			padding: 0 50px 0 18px;
			position: relative;
			background-color: #fff;
			color: var(--bg);
			border-radius: 20px;
			margin-left: 18px;
			font-size: 14px;

			&::after {

				position: absolute;
				width: 16px;
				height: 16px;
				top: 0;
				right: 16px;
				bottom: 0;
				margin: auto;
				content: '';
				border-radius: 50%;
				background-color: var(--yellow);

			}

		}

	}

}

@media #{$mdWidth} {

	.btn-menu-toggle {

		width: 48px;
		height: 48px;
		position: absolute;
		top: 10px;
		right: 8px;

		&::after,
		&::before {

			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			width: 24px;
			height: 2px;
			transition: var(--transitionDefault);
			background-color: var(--color);
			content: '';

		}

		&::before {

			transform: rotate(180deg) translate3d(0, 5px, 0);

		}

		&::after {

			transform: rotate(180deg) translate3d(0, -5px, 0);

		}

		.menu-show & {

			&::before {

				transform: rotate(45deg) translate3d(0, 0px, 0);

			}

			&::after {

				transform: rotate(-45deg) translate3d(0, 0px, 0);

			}

		}

	}

	.menu-show {

		.main {

			display: none;

		}

		.header {

			flex-grow: 1;
			border-bottom: 0;

		}

		.header__menu {

			display: block !important;

		}

		.footer {

			background-color: transparent;

		}

	}

}